import '../styles/css/eqno.css';
import { appWithTranslation } from 'next-i18next';
import Head from 'next/head';
import React, {useEffect} from "react";
import Tagmanager from "../components/tagmanager";
import Banner from "../components/cookie/banner";
import Cookies from "../lib/client/cookies";
import Script from 'next/script';
import Recaptcha from "../components/recaptcha";
import {useRouter} from "next/router";
import { AppWrapper } from '../components/context/state'; // import based on where you put it

/**
 *
 * @param metric
 */
export function reportWebVitals(metric) {
    if(process.env.DEBUG) {
        console.log(metric);
    }
}

/**
 *
 * @param Component
 * @param pageProps
 * @returns {JSX.Element}
 * @constructor
 */
function MyApp({ Component, pageProps }) {
    // init
    const router = useRouter();

    // set the api-cookie, to ensure same-site api-use
    useEffect(
        () => {
            const cookies = new Cookies();

            // check if the api-cookie exists
            if(! cookies.hasCookie('api')) {
                // set the api-cookie to ensure api use
                cookies.setCookie(
                    'api',
                    1
                );
            }
        },
        []
    );

    // render
    return (
    <>
        {/* Shared-State Context */}
        <AppWrapper>

            {/* Header */}
            <Head>
                {/* General */}
                <link rel="icon" href="/images/eqno-favicon.png" />
                <meta charSet='utf-8'/>

                {/* Browser tab config */}
                <link rel='icon' type='image/png' sizes='512x512' href='/images/eqno-logo_512.png' />
                <link rel='icon' type='image/png' sizes='384x384' href='/images/eqno-logo_384.png' />
                <link rel='icon' type='image/png' sizes='256x256' href='/images/eqno-logo_256.png' />
                <link rel='icon' type='image/png' sizes='180x180' href='/images/eqno-logo_180.png' />
                <link rel='icon' type='image/png' sizes='152x152' href='/images/eqno-logo_152.png' />
                <link rel='icon' type='image/png' sizes='144x144' href='/images/eqno-logo_144.png' />
                <link rel='icon' type='image/png' sizes='128x128' href='/images/eqno-logo_128.png' />
                <link rel='icon' type='image/png' sizes='120x120' href='/images/eqno-logo_120.png' />
                <link rel='icon' type='image/png' sizes='114x114' href='/images/eqno-logo_114.png' />
                <link rel='icon' type='image/png' sizes='96x96' href='/images/eqno-logo_96.png' />
                <link rel='icon' type='image/png' sizes='72x72' href='/images/eqno-logo_72.png' />
                <link rel='icon' type='image/png' sizes='64x64' href='/images/eqno-logo_64.png' />
                <link rel='icon' type='image/png' sizes='57x57' href='/images/eqno-logo_57.png' />
                <link rel='icon' type='image/png' sizes='48x48' href='/images/eqno-logo_48.png' />
                <link rel='icon' type='image/png' sizes='36x36' href='/images/eqno-logo_36.png' />
                <link rel='icon' type='image/png' sizes='32x32' href='/images/eqno-logo_32.png' />
                <meta name='theme-color' content='#0e2316' />

                {/* PWA specific */}
                <meta name='viewport' content='width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=5,user-scalable=yes'/>
                <link rel='manifest' href='/manifest.json' />
                <meta name='application-name' content={process.env.appName} />
                <meta name='mobile-web-app-capable' content='yes' />

                {/* Open Graph (Facebook, Twitter, LinkedIn) specific */}
                <meta property='og:site_name' content={process.env.appName} />

                {/* Apple specific */}
                <meta name='apple-mobile-web-app-capable' content='yes' />
                <meta name='apple-mobile-web-app-status-bar-style' content='default' />
                <meta name='apple-mobile-web-app-title' content={process.env.appName} />
                <link rel='apple-touch-icon' sizes='48x48' href='/images/eqno-logo_maskable_icon_48.png' />
                <link rel='apple-touch-icon' sizes='72x72' href='/images/eqno-logo_maskable_icon_72.png' />
                <link rel='apple-touch-icon' sizes='96x96' href='/images/eqno-logo_maskable_icon_96.png' />
                <link rel='apple-touch-icon' sizes='128x128' href='/images/eqno-logo_maskable_icon_128.png' />
                <link rel='apple-touch-icon' sizes='192x192' href='/images/eqno-logo_maskable_icon_192.png' />
                <link rel='apple-touch-icon' sizes='256x256' href='/images/eqno-logo_maskable_icon_256.png' />
                <link rel='apple-touch-icon' sizes='384x384' href='/images/eqno-logo_maskable_icon_384.png' />
                <link rel='apple-touch-icon' sizes='512x512' href='/images/eqno-logo_maskable_icon_512.png' />
                <link rel='apple-touch-icon' sizes='1024x1024' href='/images/eqno-logo_maskable_icon_1024.png' />
                <link rel='mask-icon' href='/images/eqno-logo_maskable_icon.svg' color='#105028' />

                {/* Microsoft specific */}
                <meta name='X-UA-Compatible' content='IE=edge'/>
                <meta name='msapplication-config' content='/browserconfig.xml' />
                <meta name='msapplication-TileColor' content='#105028' />
                <meta name='msapplication-TileImage' content='/images/eqno-logo_144.png' />
                <meta name='msapplication-tap-highlight' content='no' />

                {/* Facebook specific */}
                <meta property='fb:app_id' content={process.env.facebook.appId} />

                {/* Twitter specific */}
                <meta name='twitter:card' content={'summary'} />
                <meta name='twitter:site' content={process.env.twitter.site} />
                <meta name='twitter:creator' content={process.env.twitter.creator} />
            </Head>

            {/* Scripts */}
            <Script src={"/scripts/@popperjs/core/dist/umd/popper.min.js"} strategy={'beforeInteractive'} />
            <Script src={"/scripts/bootstrap/dist/js/bootstrap.min.js"} strategy={'beforeInteractive'} />

            {/* Body */}
            <main>
                {/* Recaptcha */}
                <Recaptcha
                    siteKey={process.env.recaptchaKey}
                    action={']' === router.pathname.substring(router.pathname.length - 1, router.pathname.length) ? ('/page' + router.asPath.substring(0, router.asPath.indexOf('#')).replace(/%20/g, '_')) : ('/page' + router.pathname)}
                />

                {/* Google Tag Manager */}
                {'production' === process.env.NODE_ENV ? <Tagmanager /> : ''}

                {/* Body */}
                <div className="container-fluid bg-light p-0">
                    <Component {...pageProps} />
                </div>

                {/* Cookie-Banner */}
                <Banner />
            </main>
        </AppWrapper>
    </>
    )
}

//export default MyApp;
export default appWithTranslation(MyApp);

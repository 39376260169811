/**
 *
 */
class Cookies
{
    /**
     *
     */
    constructor() {
        this.prefix = 'eqno-';
        this.defaultExpirationOffset = 2592000 // 1 month;
    }

    /**
     *
     * @param key
     */
    createKey(key) {
        return this.prefix + key;
    }

    /**
     *
     * @param key
     * @param value
     * @param expires
     */
    setCookie(
        key,
        value
    ) {
        // create the expiration date, which is every three months
        let date = new Date(Date.now());
        const expirationDate = new Date(date.setMonth(date.getMonth() + 3));

        // init the cookie
        let cookie = this.createKey(key) + '=' + value + ';expires=' + expirationDate + ';path=/;';

        // append that the cookie is only used on the same site
        cookie += 'SameSite=Lax;';

        // append secure-flag, when it is not the development-environment
        if('Development' !== process.env.environment) {
            cookie += 'Secure;';
        }

        // set the cookie
        document.cookie = cookie;
    }

    /**
     *
     * @param key
     */
    getCookie(key) {
        const matches = document.cookie.match(new RegExp('(^| )' + this.createKey(key) + '=([^;]+)'));
        return matches ? matches[2] : null;
    }

    /**
     *
     * @param key
     * @returns {boolean}
     */
    hasCookie(key) {
        return Boolean(this.getCookie(key))
    }

    /**
     *
     * @param key
     */
    removeCookie(key) {
        this.setCookie(
            this.createKey(key),
            'remove',
            Date.now() - this.defaultExpirationOffset // expires in the past for deletion
        )
    }
}

export default Cookies;
import React from "react";
import {useTranslation} from "next-i18next";
import Switch from "../form/input/switch";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Body ({ acceptAllCallback, acceptSelectionCallback }) {
    // init translation
    const { t } = useTranslation();

    // accept all cookies
    function acceptAll(event) {
        acceptAllCallback(event);
    }

    // accept selection cookies
    function acceptSelection(event) {
        acceptSelectionCallback(event);
    }

    // render
    return (
        <>
            <div className="toast-body">
                <p>{t('using.cookies')}</p>
                <div className="mt-2 mb-3">
                    <Switch disabled={true} name={'essential'} label={t('necessary')} checked={true} inline={true} />
                    <Switch disabled={false} name={'analytics'} label={t('analytics')} checked={false} inline={true} />
                </div>
                <div className="row mt-2 pt-2 border-top">
                    <button onClick={acceptAll} type="button" className="col-5 btn btn-secondary btn-sm ms-2 me-1" data-bs-dismiss="toast">{t('accept.all')}</button>
                    <button onClick={acceptSelection} type="button" className="col-5 btn btn-secondary btn-sm ms-1 me-1" data-bs-dismiss="toast">{t('accept.selection')}</button>
                </div>
            </div>
        </>
    );
}
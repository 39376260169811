import React from "react";
import Script from 'next/script';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Tagmanager () {
    // render
    return (
        <>
            <Script id="tagmanager" strategy="lazyOnload">
                {`
                    // Define dataLayer and the gtag function.
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    
                    // Query-parameters will be used instead if analytics is denied
                    gtag('set', 'url_passthrough', true);
                
                    // Default ad_storage and analytics_storage to 'denied'.
                    gtag('consent', 'default', {
                        'ad_storage': 'denied',
                        'analytics_storage': 'denied',
                        'functionality_storage': 'granted',
                        'personalization_storage': 'denied',
                        'security_storage': 'granted'
                    });
                    
                    // Redact ads data
                    gtag('set', 'ads_data_redaction', true);
                    
                    // Init tag-manager
                    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                    })(window,document,'script','dataLayer','GTM-WSLFM9L');
                `}
            </Script>
            <noscript>
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WSLFM9L" height="0" width="0" style={{display: 'none', visibility: 'hidden'}}/>
            </noscript>
        </>
    );
}
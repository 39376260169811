import {createContext, useContext, useState} from 'react';

/**
 * Create the context
 * @type {React.Context<unknown>}
 */
const StateContext = createContext('state');

/**
 *
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export function AppWrapper({ children }) {
    // init
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
    let sharedState = {
        recaptchaLoaded: recaptchaLoaded,
        setRecaptchaLoaded: setRecaptchaLoaded,
        theme: 'light',
        toggleTheme: () => {'light' === this.theme ? this.theme = 'dark' : this.theme = 'light'}
    };

    // render
    return (
        <StateContext.Provider value={sharedState}>
            {children}
        </StateContext.Provider>
    );
}

/**
 * Hook
 * @returns {unknown}
 */
export function useStateContext() {
    return useContext(StateContext);
}
import React, {useEffect, useRef} from "react";
import Header from "./header";
import Body from "./body";
import Cookies from "../../lib/client/cookies";
import Tagmanager from "../../lib/client/tagmanager";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Banner () {
    // init variables
    const bannerRef = useRef();

    // show the element, if the consent-cookie is not set
    useEffect(
        () => {
            const cookies = new Cookies();
            if(! cookies.hasCookie('consent')) {
                let toast = new bootstrap.Toast(bannerRef.current);
                toast.show();
            } else {
                // add the saved consent
                const consent = ('0' !== cookies.getCookie('analytics')) ? 'granted' : 'denied';
                let tagmanager = new Tagmanager;
                tagmanager.add(
                    'consent',
                    'update',
                    {
                        'ad_storage': consent,
                        'analytics_storage': consent
                    }
                );
            }
        },
        []
    );

    // default accept
    function accept(
        event,
        analytics
    ) {
        event.preventDefault();

        // set the consent-cookie, which indicates the he has already seen and accepted the consent
        let cookies = new Cookies();
        cookies.setCookie(
            'consent',
            1
        );

        // set the analytics-cookie
        cookies.setCookie(
            'analytics',
            analytics ? 1 : 0
        );

        // update the consent-stats
        const consent = ('0' !== cookies.getCookie('analytics')) ? 'granted' : 'denied';
        let tagmanager = new Tagmanager;
        tagmanager.add(
            'consent',
            'update',
            {
                'ad_storage': consent,
                'analytics_storage': consent
            }
        );
    }

    // accept all cookies
    function acceptAll(event) {
        accept(
            event,
            true
        );
    }

    // reject all cookies
    function rejectAll(event) {
        accept(
            event,
            false
        );
    }

    // accept selection cookies
    function acceptSelection(event) {
        accept(
            event,
            document.getElementById('analytics').checked
        );
    }

    // render
    return (
        <>
            <div aria-live="polite" aria-atomic="true" className="bg-white position-fixed top-50 start-50 translate-middle">
                <div ref={bannerRef} className={"toast"} data-bs-autohide={false} role="alert" aria-live="assertive" aria-atomic="true">
                    <Header rejectAllCallback={rejectAll} />
                    <Body acceptAllCallback={acceptAll} acceptSelectionCallback={acceptSelection} />
                </div>
            </div>
        </>
    );
}
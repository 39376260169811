/**
 *
 */
class Tagmanager
{
    /**
     *
     */
    constructor() {
        window.dataLayer = window.dataLayer || [];
    }

    /**
     *
     */
    add() {
        window.dataLayer.push(arguments);
    }
}

export default Tagmanager;
/**
 *
 */
class Recaptcha {
    /**
     *
     * @param siteKey
     */
    constructor(siteKey) {
        this.siteKey = siteKey;
        this.url = "https://www.google.com/recaptcha/enterprise.js?render=" + encodeURIComponent(siteKey);
    }

    /**
     *
     * @param action
     * @returns {Promise<unknown>}
     */
    execute(action) {
        return new Promise(resolve => {
            grecaptcha.enterprise.execute(
                this.siteKey,
                {
                    action: action
                }
            ).then(function(token) {
                resolve(token);
            });
        });
    }

    /**
     *
     * @param action
     * @param token
     * @returns {Promise<unknown>}
     */
    assess(
        action,
        token
    ) {
        return new Promise(resolve => {
            // make the search-request
            fetch('/api/assess?action=' + encodeURIComponent(action) + '&token=' + encodeURIComponent(token))
                .then(response => response.json())
                .then(data => {
                    if('ok' === data.header.status) {
                        resolve(data.header.assessmentScore);
                    } else {
                        resolve(data.header.errors);
                    }
                })
                .catch(reason => resolve([reason]));
        });
    }

    /**
     *
     * @param action
     * @returns {Promise<unknown>}
     */
    load(action) {
        return new Promise(resolve => {
            grecaptcha.enterprise.ready(
                async () => {
                    const token = await this.execute(action);
                    /*const score = await this.assess(
                        action,
                        token
                    );
                    resolve(score);*/
                    resolve(token);
                }
            );
        });
    }
}

export default Recaptcha;
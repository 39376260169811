import React from "react";
import Script from 'next/script';
import RecaptchaClient from "../lib/client/recaptcha";
import {useStateContext} from "./context/state";

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export default function Recaptcha({ siteKey, action }) {
    // init
    const recaptchaClient = new RecaptchaClient(siteKey);
    let stateContext = useStateContext();

    // render
    return (
        <>
            <Script
                id="recaptcha-src"
                src={recaptchaClient.url}
                strategy="lazyOnload"
                onLoad={() => {
                    recaptchaClient.load(action).
                    then(response => {
                        stateContext.setRecaptchaLoaded(true);
                    })
                    .catch(error => {
                        console.log(error);
                    });
                    //const score = await recaptchaClient.load(action);
                    // todo redirect to a bot page if the score is too low
                }}
            />
        </>
    );
}


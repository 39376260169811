import React from "react";

/**
 *
 * @param name
 * @param checked
 * @param label
 * @param disabled
 * @param inline
 * @returns {JSX.Element}
 * @constructor
 */
export default function Switch ({ name, checked, label, disabled, inline }) {
    // render
    return (
        <>
            <div className={"form-check form-switch" + (inline ? ' form-check-inline' : '')}>
                <input defaultChecked={checked} name={name} className="form-check-input" role="switch" type="checkbox" id={name} disabled={disabled} />
                <label className="form-check-label" htmlFor={name}>{label}</label>
            </div>
        </>
    );
}